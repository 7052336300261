import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,

  productionAppUrl: 'https://www.proceduremanager.com',
  developmentKeyHash: '$2a$08$AiyrNS9ETPMWVXm7B3xAG.tIYoy1ZuP0CIf.Qm/xtKvsksc65qvsi',

  appUrl: 'https://www.proceduremanager.work',
  backendUrl: 'https://api.proceduremanager.work',
  helpUrl: 'https://help.proceduremanager.work',
  contactEmail: 'info@proceduremanager.com',

  loggingEnabled: false,
  loggingUrl: null,

  isServiceWorkerEnabled: true,

  analytics: {
    trackingEnabled: false,

    googleAnalyticsAccountId: 'UA-142546788-1',
  },

  featureSwitches: {

  },
};
